import classNames from 'classnames';
import React from 'react';

import RewindMenu from 'common/components/RewindMenu';
import CopyButton from 'common/components/socialNetworks/CopyButton';
import FacebookShareButton from 'common/components/socialNetworks/FacebookShareButton';
import TwitterShareButton from 'common/components/socialNetworks/TwitterShareButton';
import WhatsAppShareButton from 'common/components/socialNetworks/WhatsAppShareButton';
import { USERSPACE_ROUTES, IS_USERSPACE } from 'common/configuration/constants';
import { displayRewind } from 'common/configuration/rewind';
import { TrackingEventNames } from 'common/constants/trackingEventsNames';
import { Origin } from 'common/module/tracking/permanentSpeednoterButtonTracking';
import eventEmitter from 'common/services/events/eventEmitter';
import { path } from 'common/tools/network/routing';
import trans from 'common/tools/translations/trans';
import { ReduxUser } from 'common/types';

import UserMenuBorderedItem from './UserMenuBorderedItem';

type Props = { user: Partial<ReduxUser> };
const UserMenu = ({ user }: Props) => {
  const userprofileUrl = path(
    'userprofile_index',
    { user: user.account?.id },
    true
  );

  const navLinks = [];

  if (displayRewind(user.account?.id)) {
    navLinks.push(
      <li
        key="rewind-menu-key"
        className={classNames(
          'header-nav-userspace-item',
          'userspace-item-bordered',
          'rewind-menu'
        )}
      >
        <RewindMenu className="header-nav-link" logged={user.loggedIn} />
      </li>
    );
  }

  navLinks.push(
    Object.values(USERSPACE_ROUTES).map((route, index, array) => {
      const key = route.path;
      const classes = classNames(
        'header-nav-userspace-link',
        'icon',
        route.icon
      );
      const text = trans(route.label);
      return (
        <li
          key={key}
          className={classNames('header-nav-userspace-item', {
            'userspace-item-bordered': index === array.length - 1
          })}
        >
          <a href={path(route.path, {}, true)} className={classes}>
            {text}
          </a>
        </li>
      );
    })
  );

  navLinks.push(
    <UserMenuBorderedItem
      key="public-profile-key"
      icon="icon-profil"
      href={userprofileUrl}
      text={trans('userspace.menu.public-profile.title')}
    />
  );

  navLinks.push(
    <UserMenuBorderedItem
      key="permanent-speednoter-key"
      icon="icon-star-empty"
      href={path('userspace_permanent_speednoter')}
      text={trans('header.label.permanent-speednoter')}
      onClick={() => {
        eventEmitter.emit(
          TrackingEventNames.OPEN_PERMANENT_SPEEDNOTER_BUTTON_CLICK,
          Origin.MyBrandMenu
        );
      }}
    />
  );

  navLinks.push(
    <UserMenuBorderedItem
      key="disconnect-key"
      className="disconnect"
      icon="icon-deconnexion"
      href={path(
        'signout',
        IS_USERSPACE ? {} : { returnUrl: window.location.href },
        true
      )}
      text={trans('menu.label.mybrand.logoff')}
    />
  );

  navLinks.push(
    <li
      key="share-buttons-key"
      className="header-nav-userspace-item share-buttons"
    >
      <div className="share-buttons-title">
        {trans('userspace.share.my-profile')}
      </div>
      <div className="share-buttons-container">
        <FacebookShareButton link={userprofileUrl} size="sm" />
        <TwitterShareButton link={userprofileUrl} size="sm" />
        <WhatsAppShareButton link={userprofileUrl} size="sm" />
        <CopyButton link={userprofileUrl} size="sm" />
      </div>
    </li>
  );
  return <>{navLinks}</>;
};

export default UserMenu;
