import intersection from 'lodash/intersection';
import union from 'lodash/union';

import {
  PartialDate,
  ReleaseTag,
  ReleaseTagType
} from 'types/graphql-api.generated';

import { getCurrentUser } from 'common/api/GraphApi';
import { PartialDate as PartialDateClass } from 'common/api/PartialDate';
import { PartialDatePrecision } from 'common/api/PartialDateType';
import { loginSuccessMapper } from 'common/reducers/user';

export type EntityWithReleases = {
  releases?: Array<{
    releaseDate?: Pick<PartialDate, 'date' | 'precision' | '__typename'> | null;
    releaseTags?: Pick<ReleaseTag, 'tagTypes' | '__typename'> | null;
    __typename?: 'MovieRelease' | 'SeriesRelease';
  } | null> | null;
};

const squashEdges = (
  data: any[] | Record<string, any>
): Record<string, any> => {
  if (typeof data !== 'object') {
    return data;
  }

  // returns a copy of the array without the intermediate nodes
  if (Array.isArray(data)) {
    return data.map(item => {
      if (item.node) {
        // remove the intermediate node
        return squashEdges(item.node);
      }
      return squashEdges(item);
    });
  }

  // returns a copy of the object without intermediate nodes
  const simplifyData: Record<string, any> = {};
  let key;
  for (key in data) {
    if (data[key] && data[key].edges) {
      // delete the intermediate node accessing the array
      simplifyData[key] = squashEdges(data[key].edges);
    } else {
      simplifyData[key] = data[key] ? squashEdges(data[key]) : null;
    }
  }

  return simplifyData;
};

export const simplifyGraphData = (response: {
  data: any[] | Record<string, any>;
}) => {
  if (!response || !response.data) {
    return {};
  }

  return squashEdges(response.data);
};

// The eligible release types.
// Returned release types order will be the same as this one.
//
// Attention !!!
// This list is also defined and used for php in the file
//
// src/GraphBundle/Helper/Release/ReleaseHelper.php
//
// If you change this list, you will have to change it in the PHP file as well.
const eligibleReleaseTagTypes = [
  ReleaseTagType.Theater,
  ReleaseTagType.OnlineVod,
  ReleaseTagType.OnlineVodest,
  ReleaseTagType.Physical,
  ReleaseTagType.PhysicalBluray,
  ReleaseTagType.PhysicalDvd,
  ReleaseTagType.OnlineSvod,
  ReleaseTagType.Replay
];

/**
 * Get first release date, with release types
 *
 * Attention !!!
 * This method is a copy of the one defined in the file
 *
 * src/GraphBundle/Helper/Release/ReleaseHelper.php
 *
 * Please keep the same logic in both files.
 *
 * @param {MovieReleasesFragmentFragment} entity
 */
export const getFirstReleaseInfos = (
  entity?: EntityWithReleases
): { date: PartialDateClass | null; releaseTypes: ReleaseTagType[] } => {
  // Get the first release date and filter releases list matching it
  let firstReleaseDate: PartialDateClass | null = null;
  const releases = (entity?.releases ?? [])
    .filter(release => {
      if (
        release?.releaseDate?.date &&
        (release.releaseTags?.tagTypes ?? []).some(tag =>
          eligibleReleaseTagTypes.includes(tag)
        )
      ) {
        const releaseDate = new PartialDateClass(
          release.releaseDate.date,
          release.releaseDate.precision as PartialDatePrecision
        );
        if (
          !firstReleaseDate ||
          !firstReleaseDate.date ||
          releaseDate.date < firstReleaseDate.date
        ) {
          firstReleaseDate = releaseDate;
        }
        return true;
      }
      return false;
    })
    .filter(release => release?.releaseDate?.date === firstReleaseDate?.date);

  // Compute data
  const releaseTypes = releases
    .reduce(
      (acc: ReleaseTagType[], release) =>
        union(
          intersection(release?.releaseTags?.tagTypes, eligibleReleaseTagTypes),
          acc
        ),
      []
    )
    .sort(
      // Reorder to match the order defined by eligibleReleaseTagTypes
      (release1, release2) =>
        eligibleReleaseTagTypes.indexOf(release1) >
        eligibleReleaseTagTypes.indexOf(release2)
          ? 1
          : -1
    );

  return {
    date: firstReleaseDate,
    releaseTypes
  };
};

export const getParsedUserData = async () => {
  const response = await getCurrentUser();
  return loginSuccessMapper(response);
};
