import { BRAND_HAS_CONTROL_OVER_RATINGS } from 'common/configuration/constants';

/**
 * If the film has a theatrical release date :
 *
 * in the past: rating possible without review
 * in the future: mandatory review
 * If the film has no theatrical release date :
 *
 * and the year of production is >= 3 years from today (e.g., an old film with no exact theatrical release date):
 * rating is possible without review,
 * and the year of production is < 3 years from today
 * (e.g., the film will be released soon, but we don't really know when):
 * mandatory review.
 *
 * @param entity
 */
export const isBoundByAReview = (
  entity?: {
    releaseDate?: string | Date | null;
    productionYear?: number | null;
  } | null
) => {
  const today = new Date();
  return Boolean(
    BRAND_HAS_CONTROL_OVER_RATINGS &&
      (entity?.releaseDate
        ? new Date(entity.releaseDate) > today
        : entity?.productionYear &&
          entity.productionYear > today.getFullYear() - 3)
  );
};
